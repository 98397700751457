<template>
  <div class="app">
    <comhd @search="dosearch"></comhd>
    <div class="main">
      <md-scroll-view
          :scrolling-x="false"
          auto-reflow
          @end-reached="handleToEnd"
          @refreshing="handleToTop"
          ref="sc"
      >
        <md-scroll-view-refresh
            slot="refresh"
            slot-scope="{ scrollTop, isRefreshActive, isRefreshing }"
            :scroll-top="scrollTop"
            :is-refreshing="isRefreshing"
            :is-refresh-active="isRefreshActive"
        ></md-scroll-view-refresh>
            <md-tab-bar
                v-model="orderBy"
                :items="items"
                :maxLength="5"
            />
            <md-tab-bar
                v-model="typeBy"
                :items="fenlei"
                :maxLength="5"
            />
          <div class="zbList">
            <div class="zbItem" v-for="item of list" :key="item.aid" @click="$router.push({name:'detail',query:{id:item.aid}})">
              <div class="cover">
                <img :src="item.cover" alt="">
              </div>
              <div class="info">
                <div class="title">{{ item.title.slice(0,20) }}</div>
                <div class="detail">
                  <div class="author">{{ item.author }}</div>
                  <div class="time">{{item.addTime|format('YYYY-MM-DD')}}</div>
                </div>
              </div>
            </div>
          </div>
        <md-scroll-view-more
            slot="more"
            :is-finished="isFinished"
        >
        </md-scroll-view-more>
        </md-scroll-view>
      </div>
  </div>



</template>

<script>
export default {
  name: "zbList",
  data(){
    return {
      items: [
        {name: 1, label: '直播'},
        {name: 2, label: '录播'},
      ],
      subCates:[

      ],
      curCate:null,
      orderBy: 1,
      typeBy:'',
      list:[],
      page:1,
      count:0,
      isFinished:false,
      keyword:'',
      istuwen2:false,
      fenlei:[]
    }
  },

  watch:{
    curCate(nv){
      this.isFinished = false;
      this.list = []
      this.getData();
      this.getCateInfo()
    },
    typeBy(nv){
      this.isFinished = false;
      this.list = []
      this.getData();
      this.getCateInfo()
    }
  },
  created(){
    if(this.$route.query.big == 1) this.istuwen2 = true
  },
  mounted(){
    this.getData()
    this.getSubCates()
    this.getCateInfo()

  },
  methods:{
    async getCateInfo(){
      const cid = this.curCate ?  this.curCate : this.$route.query.cid
      const res = await this.$api.post('/mapi/getCateInfo',{cid})
      const { success,data } = res.data;
      if(success){
        this.fenlei = []
        if(data.tags) this.fenlei = data.tags.split(",").map(r=>({
          name: r,
          label: r
        }))
        this.fenlei.length >0 ?this.fenlei.unshift({name:'',label:'全部'}):[]
      }

    },
    async dosearch(res){
      this.keyword = res
      this.isFinished = false;
      this.page = 1;
      this.list = []
      this.getData()
    },
    async getSubCates(){
      let res = await this.$api.post('/mapi/getSubCates',{
        cid:this.$route.query.cid
      })
      const { success,data } = res.data
      if(success){
        this.subCates = data.map(r=>({
          name:r.cid,
          label:r.name
        }))
        this.curCate = this.$route.query.cid

      }

    },
    async handleTabChange(){
      this.list = []
      this.page = 1;
      this.getData();
      this.isFinished = false
    },
    async getData(){
      if(this.isFinished)  return this.$toast.info('已经没有更多的了')
      let { cid } = this.$route.query
      let order = '';
      if(this.curCate) cid = this.curCate
      if(this.orderBy === 1) order=''
      if(this.orderBy === 2) order='byview'
      if(this.orderBy === 3) order='bytime'
      let res = await this.$api.post('/mapi/acticleList',{
        cid,
        order,
        page:this.page,
        limit:10,
        keyword:this.keyword,
        type:this.typeBy
      })
      const  { count,rows,success}  = res.data;
      if(success){
        this.count = count
        this.list = this.list.concat(rows)
        this.$refs.sc.finishLoadMore()
        if(this.list.length >= this.count) this.isFinished = true
      }

    },
    async handleToEnd(){
      if(!this.isFinished)  this.page++
      this.getData()

    },
    async handleToTop(){
      this.list = []
      this.page = 1;
      this.isFinished = false
      await this.getData()
      this.$refs.sc.finishRefresh()
    }
  }
}
</script>

<style scoped>

.main{
  height: 0;
  flex-grow: 1;
}
</style>
